import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MessageSentSvg from "../images/message_sent.svg";

function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Merci pour votre message"
      />
<div className="mx-auto max-w-screen-xl">
  <div className=" pt-10">
    <h1>Merci pour votre message</h1>
    <div className="text-center max-w-xl mx-auto">
      <p>Nous prendrons contact avec vous dans les plus brefs délais.</p>
    </div>
  </div>
  
  <div className="text-center mt-10">
    <img className="mt-10" src={MessageSentSvg} alt="Message envoyé"/>
  </div>
</div>
    </Layout>
  );
}

export default VisitePage;
